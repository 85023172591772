"use client";
import { useEffect, useState } from "react";
import useWalletAccount from "../hooks/useWalletAccount";
import { truncateAddress } from "../../utils/truncateAddress";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from "@rainbow-me/rainbowkit";

const WalletConnectButton = () => {
  const {
    isConnected,
    connectedChain,
    isWrongNetWork,
    isDisconnected,
    account,
    status,
  } = useWalletAccount();
  const { openConnectModal } = useConnectModal();
  const { openAccountModal } = useAccountModal();
  const { openChainModal } = useChainModal();

  const [btnLabel, setBtnLabel] = useState<`0x${string}` | string | undefined>(
    "Connect Wallet"
  );

  const action = () => {
    if (isConnected == false) {
      return openConnectModal!();
    } else if (isConnected && connectedChain) {
      return openAccountModal!();
    }
    if (isWrongNetWork && isConnected) {
      return openChainModal!();
    }
  };

  useEffect(() => {
    if (isDisconnected) {
      setBtnLabel("Connect Wallet");
    } else if (status === "reconnecting") {
      setBtnLabel("Reconnecting ...");
    } else if (status === "connecting") {
      setBtnLabel("Connecting wallet...");
    } else if (isConnected) {
      setBtnLabel(truncateAddress(account!));
    }
    if (isWrongNetWork && isConnected) {
      setBtnLabel("Wrong network");
    }
  }, [
    isConnected,
    connectedChain,
    isWrongNetWork,
    isDisconnected,
    status,
    account,
  ]);

  return (
    <div
      className="cursor-pointer flex radial-gradient-button"
      onClick={action}
    >
      {btnLabel}
      {/* {isWrongNetWork && isConnected && (
        <div className="ml-5">{truncateAddress(account!)}</div>
      )} */}
    </div>
  );
};

export default WalletConnectButton;
